import React from 'react'
import Header from './Header';
import Membership from './Membership';
import Join from './Join';
import Think from './Think';
import {useStaticQuery, graphql} from "gatsby";
const Index = () => {
    const data = useStaticQuery(graphql`
    query homeQuery {
      allSanityHome {
        edges {
          node {
            sections {
              mention
              description
              mainImage {
                alt
                asset {
                  gatsbyImageData(placeholder: BLURRED, formats: [WEBP, AVIF], fit: FILL, width: 800)
                }
              }
            }
          }
        }
      }
    }
      
  `);
  const homeData = data.allSanityHome.edges[1].node;
 
    return (
        <div>
            <Header homeData={homeData.sections[0]}/>
            <Membership homeData={homeData.sections[1]}/>
            <Join homeData={homeData.sections[2]}/>
            <Think />
        </div>
    )
}

export default Index
