import React from "react";
import Layout from "../components/Layout";
import Index from "../components/home/Index";
import { Helmet } from 'react-helmet'

const IndexPage = () => {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Auto Genius - The Automotive Marketing Community Slack Group</title>
        <meta
          name="description"
          content="Join Auto Genius, where leading high-growth automotive marketers get help, share tactics and innovate in a private marketing Slack community."
        />
      </Helmet>
    <Layout>
      <Index />
    </Layout>
    </>
  );
};

export default IndexPage;
